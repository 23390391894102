.btn-primary {
 @apply bg-btn-primary border border-btn-primary drop-shadow rounded-xl flex items-center justify-center !font-bold text-[14px] text-text-btn-primary min-h-12 px-4 transition-all duration-300;
}

.btn-primary:hover {
  @apply bg-btn-primary-hover border-btn-primary-hover text-text-btn-primary-hover shadow;
}

.btn-primary > svg {
  @apply w-6 h-6;
}

.btn-disabled,
.btn[disabled],
.btn-primary[disabled] {
  pointer-events: none;
}

.btn-disabled,
.btn-disabled:hover,
.btn[disabled],
.btn[disabled]:hover,
.btn-primary[disabled]:hover,
.btn-secondary[disabled]:hover {
  background-color: #0003!important;
  color: #3333!important;
  border: #0003!important;
}

.btn-square {
  height: 3rem;
  width: 3rem;
  padding: 0;
}

.btn.loading,.btn.loading:hover {
  pointer-events: none;
}

.btn.\!loading,.btn.\!loading:hover {
  pointer-events:none!important;
}

.btn.loading:before {
  margin-right: .5rem;
  height: 1rem;
  width: 1rem;
  border-radius: 9999px;
  border-width: 2px;
  animation: spin 2s linear infinite;
  content: "";
  border-color: transparent currentColor currentColor transparent;
}

.btn.\!loading:before {
  margin-right:.5rem!important;
  height: 1rem!important;
  width: 1rem!important;
  border-radius: 9999px!important;
  border-width: 2px!important;
  animation: spin 2s linear infinite!important;
  content: ""!important;
  border-color: transparent currentColor currentColor transparent!important;
}

.btn {
  @apply rounded-xl min-w-40 text-sm text-center leading-4 px-4 min-h-12 h-12 font-medium transition-all cursor-pointer;
}

.btn-sm {
  @apply min-h-8 h-8 !min-w-fit;
}
.btn-primary-outline {
  @apply w-max text-center rounded-xl border-2 border-btn-primary bg-white min-h-12 px-4 text-sm text-btn-primary font-bold hover:bg-btn-primary hover:border-btn-primary hover:text-white transition-all;
}

.btn-outline {
  @apply bg-transparent border border-current transition-all;
}

.btn-outline:hover {
  @apply bg-black text-white;
}

.btn-error {
  @apply bg-error border border-error text-white;
}

.btn-error:hover {
@apply bg-error-hover border-error-hover shadow;
}

.btn-ghost {
@apply bg-transparent h-8 min-w-8 rounded-xl flex items-center justify-center transition-all;
}

.btn-ghost:hover {
  @apply bg-secondary-light;
}

.btn-success {
  @apply flex items-center justify-center bg-btn-success border border-btn-success text-text-btn-success text-sm hover:shadow hover:bg-btn-success-hover hover:border-btn-secondary-hover transition-all;
}

.textarea {
  @apply py-1 px-3 text-base leading-8 min-h-12 w-full bg-white border border-btn-ghost rounded-xl;
}

.textarea:focus {
  outline: 2px solid #3333;
  outline-offset: 2px;
}

.textarea:disabled {
  @apply bg-item-disabled border-item-disabled resize-none cursor-default;
}

.input {
  height: 38px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  border-width: 1px;
  border-radius: .5rem;
}

.input-bordered:focus {
  @apply outline-none focus:ring-2 transition duration-100 focus:border-border-focus focus:ring-ring-color-focus;
}

.primary-input {
  @apply drop-shadow-sm text-sm w-full border border-border-light rounded focus:outline-none focus:ring-0 bg-transparent text-item-color pl-4 pr-4 py-2 placeholder:text-text-hint;
}

.ReactModalPortal {
  z-index: 100;
  position: relative;
}

.ReactModalPortal ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  margin-right: 20px;
}

.ReactModalPortal ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, .5);
}

.ReactModalPortal ::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, .1);
}

.ReactModal__Overlay {
  background-color: #0000004d !important;
}

.draggable-element {
  -webkit-user-drag: auto;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.fade-scale-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-scale-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.fade-scale-exit {
  opacity: 1;
}

.fade-scale-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.hidden-checkbox {
  @apply w-6 h-6 sr-only;
}

.styled-checkbox {
  @apply w-6 h-6 relative inline-block border rounded-lg border-btn-primary cursor-pointer transition-all;
}

.hidden-checkbox:checked + .styled-checkbox {
  @apply bg-btn-primary border-btn-primary;
}

.hidden-checkbox:checked + .styled-checkbox:hover {
  @apply bg-btn-primary border-btn-primary;
}

.custom-checkbox:hover .styled-checkbox {
  @apply border-btn-primary;
}

.custom-checkbox input:checked + .styled-checkbox::before {
  @apply text-text-btn-primary absolute block border-text-btn-primary;
  content: '';
  width: 0.6rem;
  height: 0.9rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.custom-dropdown-menu {
  padding: 8px!important;
  @apply flex flex-col items-center rounded-xl gap-2 !border-none;
}

.custom-dropdown-menu-item {
  padding: 4px 8px!important;
  @apply w-full text-center bg-white rounded text-text-secondary font-medium text-sm cursor-pointer transition-all;
}

.custom-dropdown-menu-item:hover {
  @apply bg-item-focus;
}

.custom-dropdown-menu-item[aria-disabled] {
  @apply bg-border-light cursor-default;
}

.primary-dropdown-menu {
  @apply rounded-xl !border-none;
}

.primary-dropdown-menu-item {
  @apply w-full !p-2.5 text-sm font-medium text-item-color cursor-pointer hover:bg-item-focus;
}

.primary-dropdown-btn {
  @apply w-full relative outline-none text-left whitespace-nowrap truncate rounded-xl focus:ring-2 transition duration-100 focus:border-border-focus focus:ring-ring-color-focus pl-4 pr-8 py-2 border bg-white text-sm text-text-secondary font-medium cursor-pointer;
}

.custom-datepicker {
  @apply w-full outline-none text-left whitespace-nowrap truncate rounded-xl focus:ring-2 transition duration-100 focus:border-border-focus focus:ring-ring-color-focus px-4 py-2 border bg-white text-sm text-text-secondary font-medium cursor-pointer;
}

.react-datepicker-wrapper {
  @apply w-full;
}

.disabled-content {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
}
