@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #555555;
}

@layer components {
  .custom-bg {
    @apply bg-no-repeat bg-svg-decor-sm sm:bg-svg-decor bg-[center_top_40px] sm:bg-[center_top_80px];
  }
}
